<template>
  <svg
    width="14"
    height="26"
    viewBox="0 0 14 26"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.957 14.4L13.668 9.767H9.22253V6.7605C9.22253 5.493 9.84353 4.2575 11.8345 4.2575H13.8555V0.313C13.8555 0.313 12.0215 0 10.268 0C6.60703 0 4.21403 2.219 4.21403 6.236V9.767H0.144531V14.4H4.21403V25.6H9.22253V14.4H12.957Z" />
  </svg>
</template>
